'use client'

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

export const projectId = '70df532b3ac4d9d0c5ea5607438919b3'

const mainnet = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed1.binance.org'
}

const testnet = {
  chainId: 97,
  name: 'Binance Smart Chain Testnet',
  currency: 'tBNB',
  explorerUrl: 'https://testnet.bscscan.com',
  rpcUrl: 'https://bsc-testnet-rpc.publicnode.com'
}

// 3. Create a metadata object
const metadata = {
  name: 'WeSendit',
  description: '',
  url: 'https://app.wesendit.io',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: mainnet.rpcUrl,
  defaultChainId: mainnet.chainId
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true,
  enableOnramp: true
})

export function Web3Modal({ children }: any) {
  return children
}
